<template>
  <page-content>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />

    <edit-field
      :fields="fields"
      :row-data="row_data"
      post-api="/bedtype/AddType"
      finish-router="bedtype"
      permission="Bed_Type_Create"
    >
      <template #facility="scope">
        <slot-facility :company-id="scope.rowData.companyID" :facility-id="scope.rowData.facilityID" :row-data="scope.rowData" :is-edit="scope.isEdit" />
      </template>
    </edit-field>
  </page-content>
</template>

<script>
import fields, { reloadFieldData } from "@/views/bedType/fields";
import EditField from "@/components/EditField";
import EditFieldHeader from "@/components/EditFieldHeader";
import PageContent from "@/components/PageContent";
import common from "@/common";
import SlotFacility from "@/views/bedType/SlotFacility";


export default {
  name: "Add",
  components: {
    SlotFacility,
    PageContent,
    EditFieldHeader,
    EditField,
    fields
  },
  data() {
    return {
      title: common.getMenuName('bedtype') + ' - ' + common.getI18n('common.add'),
      fields,
      row_data: {
        companyID: common.getCompanyId(),
        facilityID: common.getFacilityId()
      }
    }
  },
  created() {
    reloadFieldData()
  }
}
</script>

<style scoped>

</style>
